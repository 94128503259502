import { GraphQLClient } from 'graphql-request'
import {
  CreateContactanosQuery,
  CreateContactanosVariables,
  CREATE_CONTACTANOS
} from './querys/CreateContactanos'

const URI = 'https://api.hypucp.org/public/graphql'

const client = new GraphQLClient(URI)

export const createContactanos = async (input: CreateContactanosVariables) => {
  const { CreateContactanos } = await client.request<CreateContactanosQuery>(
    CREATE_CONTACTANOS,
    { input }
  )
  return CreateContactanos
}
