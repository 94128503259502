import { StaticImage } from 'gatsby-plugin-image'

export const CAROL_RIVERO = {
  name: 'Carol Rivero',
  desc: 'Educación Innovadora Principal',
  image: (
    <StaticImage
      alt="Carol Rivero"
      className="w-full h-full"
      src="../images/CAROL_RIVERO.jpg"
    />
  )
}
export const EDITH_SORIA = {
  name: 'Edith Soria',
  desc: 'Educación Co-Innovador',
  image: (
    <StaticImage
      alt="Carol Rivero"
      className="w-full h-full"
      src="../images/EDITH_SORIA.jpg"
    />
  )
}
export const JOSE_ESPINOZA = {
  name: 'José María Espinoza',
  desc: 'Ingeniero en Educación Co-Innovador',
  image: (
    <StaticImage
      alt="Carol Rivero"
      className="w-full h-full"
      src="../images/JOSE_MARIA.JPG"
    />
  )
}
export const JENNIFER_WONG = {
  name: 'Jennifer Wong',
  desc: 'Diseñadora industrial Co-Innovador',
  image: (
    <StaticImage
      alt="Carol Rivero"
      className="w-full h-full"
      src="../images/JENNIFER_WONG.jpg"
    />
  )
}
export const MIDORI_SANCHEZ = {
  name: 'Midori Sanchez',
  desc: 'Ing. Mecatrónica',
  image: (
    <StaticImage
      alt="Carol Rivero"
      className="w-full h-full"
      src="../images/MIDORI_SANCHEZ.jpg"
    />
  )
}
export const JOSE_REATEGUI = {
  name: 'José Luis Reategui',
  desc: 'Arquitecto AWS Co-Innovador',
  image: (
    <StaticImage
      alt="Carol Rivero"
      className="w-full h-full"
      src="../images/JOSE_LUIS.jpeg"
    />
  )
}
export const BRAULIO_MURILLO = {
  name: 'Braulio Murillo',
  desc: 'Project Management Professional Apoyo',
  image: (
    <StaticImage
      alt="Carol Rivero"
      className="w-full h-full"
      src="../images/BRAULIO_MURILLO.jpg"
    />
  )
}
export const GENGHIS_RIOS = {
  name: 'Genghis Ríos',
  desc: 'Especialista AWS Apoyo',
  image: (
    <StaticImage
      alt="Carol Rivero"
      className="w-full h-full"
      src="../images/GENGHIS_RIOS.jpeg"
    />
  )
}
export const CARLOS_VELIZ = {
  name: 'Carlos Veliz',
  desc: 'Especialista en inteligencia Artificial Apoyo',
  image: (
    <StaticImage
      alt="Carol Rivero"
      src="../images/CARLOS_VELIZ.jpg"
      className="w-full h-full object-left"
    />
  )
}

export const MIEMBROS = [
  CAROL_RIVERO,
  EDITH_SORIA,
  JOSE_ESPINOZA,
  JENNIFER_WONG,
  MIDORI_SANCHEZ,
  BRAULIO_MURILLO,
  GENGHIS_RIOS,
  CARLOS_VELIZ
]
