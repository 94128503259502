import { useCallback, useEffect, useMemo, useState } from 'react'

interface Props {
  sm: number
  md: number
  lg: number
  xl: number
  '2xl': number
  '3xl': number
}

type State = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | string

const breakPoints = {
  sm: 390,
  md: 768,
  lg: 1024,
  xl: 1440,
  '2xl': 1700,
  '3xl': 1920
}

const useMediaQuery = (querys: Props) => {
  const [value, setValue] = useState(1)
  const [media, setMedia] = useState<State>('sm')

  const querysMemo = useMemo(
    () => ({ ...querys }),
    [querys.sm, querys.md, querys.lg, querys.xl, querys['2xl'], querys['3xl']]
  )

  const detectMedia = useCallback(() => {
    let value = 1
    let mediaResult = 'sm'

    for (const key in breakPoints) {
      const query = (breakPoints as any)[key]
      if (window.matchMedia(`(min-width: ${query}px)`).matches) {
        mediaResult = key
        value = (querysMemo as any)[key]
      }
    }

    return { mediaResult, value }
  }, [querysMemo])

  useEffect(() => {
    const { mediaResult, value } = detectMedia()
    setValue(value)
    setMedia(mediaResult)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      const { mediaResult, value } = detectMedia()
      setMedia(mediaResult)
      setValue(value)
    })

    return window.removeEventListener('resize', () => {
      setValue(1)
      setMedia('sm')
    })
  }, [])

  return { media, value }
}

export default useMediaQuery
