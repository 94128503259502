import {
  BRAULIO_MURILLO,
  CARLOS_VELIZ,
  CAROL_RIVERO,
  EDITH_SORIA,
  GENGHIS_RIOS,
  JENNIFER_WONG,
  JOSE_ESPINOZA,
  JOSE_REATEGUI,
  MIDORI_SANCHEZ
} from '../../data/miembros'

import { Miembro } from '../../interfaces'

interface GridTextProps {
  miembro: Miembro
  className: string
  isRight?: boolean
}

const GridText = ({ className, miembro, isRight }: GridTextProps) => {
  return (
    <div className={`bg-secondary py-10 px-4 ${className}`}>
      <h4
        className="font-bold text-xl"
        style={{ textAlign: isRight ? 'right' : 'left' }}
      >
        {miembro.name}
      </h4>
      <p style={{ textAlign: isRight ? 'right' : 'left' }} className="text-xl">
        {miembro.desc}
      </p>
    </div>
  )
}

const GridEquipo = () => {
  return (
    <div className="h-[800px] hidden 3xl:flex">
      {/* Primera col */}
      <div className="w-[13vw] max-w-[237px] h-full grid grid-cols-1 grid-rows-6 gap-3 mr-3">
        <div className="h-full row-span-4 bg-gray px-5 pt-40">
          <h3 className="text-2xl 4xl:text-4xl text-white font-bold text-right">
            Equipo Innovador
          </h3>
        </div>
        <div className="h-full row-span-2 bg-primary-500"></div>
      </div>

      {/* Segunda col */}
      <div className="flex-1 grid grid-cols-6 grid-rows-20 gap-1 text-white">
        <GridText
          isRight
          miembro={CAROL_RIVERO}
          className="row-span-6 py-10 px-4"
        />
        <div className="bg-primary row-span-8">{CAROL_RIVERO.image}</div>
        <GridText
          isRight
          miembro={EDITH_SORIA}
          className="row-span-5 py-10 px-4"
        />
        <div className="bg-primary row-span-8">{EDITH_SORIA.image}</div>
        <GridText
          isRight
          miembro={JOSE_ESPINOZA}
          className="row-span-7 py-10 px-4"
        />
        <div className="bg-primary row-span-8">{JOSE_ESPINOZA.image}</div>
        <div className="bg-primary row-span-8">{MIDORI_SANCHEZ.image}</div>
        <div className="bg-primary row-span-8">{JENNIFER_WONG.image}</div>
        <div className="bg-primary row-span-8">{JOSE_REATEGUI.image}</div>
        <GridText className="row-span-4 py-5 px-4" miembro={JENNIFER_WONG} />
        <GridText className="row-span-4 py-5 px-4" miembro={MIDORI_SANCHEZ} />
        <GridText className="row-span-4 py-5 px-4" miembro={JOSE_REATEGUI} />
        <div className="bg-primary row-span-8">{BRAULIO_MURILLO.image}</div>
        <div className="bg-primary row-span-8">{GENGHIS_RIOS.image}</div>
        <div className="bg-primary row-span-8">{CARLOS_VELIZ.image}</div>
        <GridText
          isRight
          className="row-span-7 py-10 px-4"
          miembro={GENGHIS_RIOS}
        />
        <GridText
          isRight
          miembro={BRAULIO_MURILLO}
          className="row-span-6 py-10 px-4"
        />
        <GridText
          isRight
          miembro={CARLOS_VELIZ}
          className="row-span-5 py-10 px-4"
        />
      </div>

      {/* Tercera col */}
      <div className="w-[13vw] max-w-[237px] h-full grid grid-cols-1 grid-rows-5 gap-3 ml-3">
        <div className="h-full row-span-4 bg-primary-500"></div>
        <div className="h-full row-span-1 bg-gray"></div>
      </div>
    </div>
  )
}

export default GridEquipo
