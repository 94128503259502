import { ChangeEvent, FocusEvent } from 'react'
import FormError from '../FormError'
import * as styles from './input.module.css'

interface Props {
  name: string
  label: string
  value?: string
  error?: string
  isInvalid?: boolean
  onChange?: (e: ChangeEvent<any>) => void
  onBlur?: (e: FocusEvent<any, Element>) => void
}

const Input = ({
  label,
  value,
  error,
  name,
  isInvalid,
  onChange,
  onBlur
}: Props) => {
  return (
    <div className={styles.container}>
      <label htmlFor={label} className={styles.label}>
        {label}:
      </label>
      <div className="w-full col-span-7">
        <div className={styles.input_container}>
          <input
            id={label}
            type="text"
            name={name}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
        <FormError error={error} isVisible={isInvalid} />
      </div>
    </div>
  )
}

export default Input
