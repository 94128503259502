import { BsSoundwave } from 'react-icons/bs'

import Input from '../components/Input'
import Button from '../components/Button'
import { useFormik } from 'formik'
import { createContactanos } from '../api'
import FormError from '../components/FormError'
import { toast } from 'react-toastify'
import { useState } from 'react'

const isEmpty = (s: string) => s.trim().length === 0

const validate = ({
  email,
  mensaje,
  nombres
}: {
  email: string
  mensaje: string
  nombres: string
}) => {
  const errors: Record<string, string> = {}
  const regexEmail = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi

  if (isEmpty(nombres)) {
    errors.nombres = 'Campo obligatorio'
  }
  if (isEmpty(email)) {
    errors.email = 'Campo obligatorio'
  }
  if (!regexEmail.test(email)) {
    console.log({ email })
    errors.email = 'Debes ingresar un email valido'
  }
  if (isEmpty(mensaje)) {
    errors.mensaje = 'Campo obligatorio'
  }

  return errors
}

const FormContacto = () => {
  const [loading, setLoading] = useState(false)
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      validate,
      initialValues: {
        email: '',
        mensaje: '',
        nombres: ''
      },
      onSubmit: async (values, { resetForm }) => {
        setLoading(true)
        const res = await createContactanos({ ...values })
        setLoading(false)
        if (!res?.email) return

        toast('Pronto te contactaremos', { type: 'success' })

        // console.log({ res })
        resetForm()
      }
    })

  return (
    <form onSubmit={handleSubmit} className="mx-auto md:mx-0 max-w-lg w-full">
      <h3 className="text-secondary text-center lg:text-left font-bold text-3xl lg:text-4xl 2xl:text-5xl mb-12">
        Contáctanos
      </h3>

      <div>
        <Input
          label="Nombre"
          name="nombres"
          value={values.nombres}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors?.nombres}
          isInvalid={!!(errors?.nombres && touched?.nombres)}
        />

        <Input
          label="Correo"
          name="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors?.email}
          isInvalid={!!(errors?.email && touched?.email)}
        />

        <textarea
          rows={10}
          name="mensaje"
          value={values.mensaje}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Mensaje:"
          className="w-full border border-primary outline-none p-3 placeholder:text-xl placeholder:lg:text-2xl placeholder:2xl:text-3xl placeholder:text-primary placeholder:font-bold focus:border-primary-400 focus:shadow-sm focus:shadow-primary-100 transition-colors"
        />

        <FormError
          error={errors?.mensaje}
          isVisible={!!(touched?.mensaje && errors?.mensaje)}
        />

        <Button
          loading={loading}
          className="ml-auto"
          rightIcon={<BsSoundwave className="w-6 h-6 lg:w-10 lg:h-10" />}
        >
          Enviar
        </Button>
      </div>
    </form>
  )
}

export default FormContacto
