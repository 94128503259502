import { StaticImage } from 'gatsby-plugin-image'
import { CSSProperties } from 'react'

interface Props {
  className?: string
  style?: CSSProperties
}

const Logo = ({ className, style }: Props) => {
  return (
    <StaticImage
      alt="Logo PUPC"
      style={style}
      objectFit="contain"
      className={className}
      src="../../images/logo.png"
    />
  )
}

export default Logo
