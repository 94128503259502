import { MdEmail } from 'react-icons/md'
import { AiFillTwitterCircle } from 'react-icons/ai'
import {
  BsWhatsapp,
  BsFacebook,
  BsInstagram,
  BsSoundwave,
  BsCursor
} from 'react-icons/bs'
import { StaticImage } from 'gatsby-plugin-image'
import { ToastContainer } from 'react-toastify'

import Meta from '../components/Meta'
import Logo from '../components/Logo'
import Button from '../components/Button'
import GridEquipo from '../components/GridEquipo'
import SliderItem from '../components/SliderItem'
import SliderEquipo from '../components/SliderEquipo'

import { MIEMBROS } from '../data/miembros'
import BLUE_CIRCLE from '../images/recurso_footer.png'
import FormContacto from '../sections/FormContacto'

import 'react-toastify/dist/ReactToastify.css'

const Home = () => {
  return (
    <>
      <Meta />
      <main className="max-w-1920 mx-auto relative">
        <section className="w-[90%] mx-auto pt-12 lg:px-24 lg:pt-24 mb-16 lg:mb-44">
          <div className="flex justify-between">
            <Logo className="mx-auto md:mx-0 w-72 h-24 2xl:w-[354px] 2xl:h-[147px]" />

            <div className="hidden md:flex">
              <a target="_blank" rel="noreferrer" href="https://wa.link/vainkr">
                <Button
                  rightIcon={<BsCursor className="w-6 h-6 lg:w-10 lg:h-10" />}
                >
                  INICIAR SESIÓN
                </Button>
              </a>
            </div>
          </div>

          <div className="flex flex-col-reverse lg:flex-row items-center mt-10 lg:mt-20 xl:mt-10 ">
            <div className="mx-auto max-w-[90%] lg:max-w-2xl 3xl:max-w-3xl 4xl:ml-28 mr-5 xl:mr-20">
              <h1 className="text-secondary text-center lg:text-left text-lg md:text-xl xl:text-2xl 3xl:text-3xl mb-9 font-semibold">
                Encuentra la <span className="font-bold">información</span> de
                tus <span className="font-bold">cursos</span> de{' '}
                <span className="font-bold">manera rápida</span> a través de un{' '}
                <span className="font-bold">agente inteligente</span> desde tu{' '}
                <span className="font-bold">smartphone</span>.
              </h1>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/hypucp/estudiantes"
              >
                <Button
                  className="mx-auto lg:mx-0"
                  rightIcon={
                    <BsSoundwave className="w-6 h-6 lg:w-10 lg:h-10" />
                  }
                >
                  <span className="hidden md:block">
                    Agenda una demostración virtual GRATIS
                  </span>
                  <span className="md:hidden w-full text-center">
                    Demostración GRATIS
                  </span>
                </Button>
              </a>
            </div>

            <StaticImage
              objectFit="contain"
              className="w-72 lg:w-[560px] mb-10 lg:mb-0"
              alt="Hero Image"
              src="../images/hero_image.png"
            />
          </div>
        </section>

        <section className="w-[90%] mx-auto mb-16 lg:mb-44 flex items-center gap-4 sm:gap-10 3xl-gap-12 justify-center">
          <StaticImage
            alt=""
            className="w-12"
            src="../images/icon_sound_left.png"
          />
          <h2 className="text-3xl lg:text-6xl 2xl:text-7xl text-secondary text-center">
            <span className="font-bold">CONECTA</span> con el futuro
          </h2>
          <StaticImage
            alt=""
            className="w-12"
            src="../images/icon_sound_right.png"
          />
        </section>

        <section className="w-[90%] mx-auto lg:px-24 mb-16 lg:mb-44">
          <div className="grid grid-cols-1 xl:grid-cols-2 items-center justify-between gap-16">
            <StaticImage
              objectFit="contain"
              alt="Guy with a phone"
              src="../images/guy_with_phone.png"
              className="mx-auto w-[90%] max-w-lg xl:max-w-[820]"
            />

            <div className="flex-1">
              <h3 className="text-center text-3xl lg:text-5xl 3xl:text-6xl font-semibold text-secondary mb-16 xl:mb-10 2xl:mb-28">
                ¿Cómo te ayuda <span className="font-bold">HYPUCP</span>?
              </h3>

              <div className="grid grid-cols-2 gap-2 justify-around">
                <div className="flex flex-col justify-start max-w-sm">
                  <StaticImage
                    className="mb-14 w-14 mx-auto lg:w-[153px]"
                    objectFit="contain"
                    alt="Check icon"
                    src="../images/icon_check.png"
                  />

                  <p className="lg:text-xl 3xl:text-2xl text-secondary text-center">
                    Se adapta a tu estilo de aprendizaje tanto para el estudio
                    individual como grupal.
                  </p>
                </div>
                <div className="flex flex-col justify-start max-w-sm">
                  <StaticImage
                    objectFit="contain"
                    alt="Check icon"
                    src="../images/icon_check.png"
                    className="mb-14 w-14 mx-auto lg:w-[153px]"
                  />

                  <p className="lg:text-xl 3xl:text-2xl text-secondary text-center">
                    Hace uso de la inteligencia artificial y motores de
                    búsquedas especializados a través de comandos de voz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-16 2xl:mb-44">
          <h3 className="text-3xl lg:text-5xl xl:text-6xl 3xl:text-7xl text-secondary text-center mb-28">
            Pon la tecnología al servicio <br className="hidden md:block" /> de{' '}
            <span className="font-black">TU APRENDIZAJE</span>
          </h3>

          <GridEquipo />
          <SliderEquipo
            items={MIEMBROS.map((item, i) => (
              <SliderItem key={i} item={item} />
            ))}
          />
        </section>

        <section className="w-[90%] mx-auto 3xl:px-52 pb-10 lg:pb-40 flex items-center justify-center xl:justify-start relative">
          <FormContacto />
        </section>

        <div className="ml-auto xl:absolute bottom-0 right-0">
          <div className="relative">
            <div className="z-20 absolute bottom-10 right-10 2xl:bottom-36 2xl:right-36">
              <div className="flex items-center gap-3 3xl:mb-3 text-secondary">
                <MdEmail className="w-6 h-6 3xl:w-10 3xl:h-10" />
                <a
                  href="mailto:hypucp@pucp.edu.pe"
                  className="text-xl lg:text-2xl 3xl:text-3xl cursor-pointer hover:underline"
                >
                  hypucp@pucp.edu.pe
                </a>
              </div>
              <div className="flex items-center gap-3 text-secondary mb-5 3xl:mb-20">
                <BsWhatsapp className="w-6 h-6 3xl:w-10 3xl:h-10" />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.link/vainkr"
                  className="text-xl lg:text-2xl 3xl:text-3xl cursor-pointer hover:underline"
                >
                  994748708
                </a>
              </div>

              <div className="flex justify-center gap-7 mb-5">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://fb.me/hypucp"
                >
                  <BsFacebook className="w-6 h-6 xl:w-8 xl:h-8 3xl:w-12 3xl:h-12 text-secondary" />
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/hypucp/?hl=es-la"
                >
                  <BsInstagram className="w-6 h-6 xl:w-8 xl:h-8 3xl:w-12 3xl:h-12 text-secondary" />
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/hypucp"
                >
                  <AiFillTwitterCircle className="w-[28px] h-[28px] xl:w-[34px] xl:h-[34px] 3xl:w-[52px] 3xl:h-[52px] text-secondary" />
                </a>
              </div>

              <p className="text-secondary text-3xl lg:text-4xl 3xl:text-5xl text-center">
                /hypucp
              </p>
            </div>

            <img
              alt=""
              src={BLUE_CIRCLE}
              className="ml-auto w-full max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl h-full"
            />
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  )
}

export default Home
