interface Props {
  error?: string
  isVisible?: boolean
}

const FormError = ({ error, isVisible }: Props) => {
  return (
    <>
      <div className="h-5 mb-2">
        {isVisible && (
          <span className="my-2 text-sm text-red-400">{error}</span>
        )}
      </div>
    </>
  )
}

export default FormError
