import { Helmet } from 'react-helmet'

const TITLE = 'HyPUCP'
const THEME = '#00b4d6'
const URI = 'https://HyPUCP.pe'
const DESC =
  'Encuentra la información de tus cursos de manera rápida a través de un agente inteligente desde tu smartphone.'

const Meta = () => {
  return (
    <Helmet>
      <title>{TITLE}</title>
      <meta charSet="utf-8" />
      <meta name="theme-color" content={THEME} />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
      <meta name="viewport" content="width=device-width" />

      <meta name="title" content={TITLE} />
      <meta name="description" content={DESC} />
      <meta
        name="image"
        content="https://imagenes.softaki.com/app/PUCP/Logo-hypucp.png"
      />

      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESC} />
      <meta
        name="twitter:image"
        content="https://imagenes.softaki.com/app/PUCP/Logo-hypucp.png"
      />
      <meta name="twitter:url" content={URI} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:title" content={TITLE} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={TITLE} />
      <meta property="og:url" content={URI} />
      <meta
        property="og:image"
        content="https://imagenes.softaki.com/app/PUCP/Logo-hypucp.png"
      />
      <meta property="og:description" content={DESC} />
    </Helmet>
  )
}

export default Meta
