import {
  ReactNode,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState
} from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import useMediaQuery from '../../hooks/useMediaQuery'

import * as styles from './index.module.css'

interface Props {
  padding?: number
  items: ReactNode[]
  className?: string
  itemsPerView?: number
}

const SliderEquipo = ({ padding = 0, items, className }: Props) => {
  const carrouseRef = useRef<HTMLDivElement>(null)
  const [offsetWidth, setOffsetWidth] = useState(0)
  const [cursorWidth, setCursorWidth] = useState({ width: 0, position: 0 })

  const { value: ITEMS_PER_VIEW } = useMediaQuery({
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
    '2xl': 4,
    '3xl': 6
  })

  const { ITEM_PADDING } = useMemo(() => ({ ITEM_PADDING: padding }), [padding])

  const NUMBER_ITEMS = items.length
  const NUMBER_VIEWS = Number.isInteger(NUMBER_ITEMS / ITEMS_PER_VIEW)
    ? NUMBER_ITEMS / ITEMS_PER_VIEW
    : Math.floor(NUMBER_ITEMS / ITEMS_PER_VIEW) + 1
  const WIDTH_PER_ITEM = offsetWidth / ITEMS_PER_VIEW - ITEM_PADDING * 2
  const POSITIONS = Array(NUMBER_VIEWS)
    .fill(null)
    .map((_, i) => (i === 0 ? 0 : offsetWidth * i))

  useEffect(() => {
    if (carrouseRef.current?.offsetWidth) {
      setOffsetWidth(carrouseRef.current?.offsetWidth)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (carrouseRef.current?.offsetWidth) {
        setOffsetWidth(carrouseRef.current?.offsetWidth)
      }
    })

    return () => {
      setCursorWidth({ width: 0, position: 0 })
      window.removeEventListener('resize', () => {
        setOffsetWidth(0)
      })
    }
  }, [])

  const prevItem = useCallback(() => {
    setCursorWidth(({ position }) => {
      if (position === 0) return { width: 0, position: 0 }
      return { width: POSITIONS[position - 1], position: position - 1 }
    })
  }, [offsetWidth])

  const nextItem = useCallback(() => {
    setCursorWidth(({ position }) => {
      if (position === POSITIONS.length - 1) return { width: 0, position: 0 }
      return { width: POSITIONS[position + 1], position: position + 1 }
    })
  }, [POSITIONS])

  return (
    <div className={[styles.container, className].join(' ')}>
      <div ref={carrouseRef} className={styles.carrousel}>
        <div
          onClick={nextItem}
          className={styles.carrousel_wrap}
          style={{ transform: `translateX(-${cursorWidth.width}px)` }}
        >
          {items.map((item, i) => (
            <div
              key={i}
              className={styles.item}
              style={{ minWidth: WIDTH_PER_ITEM, paddingInline: 5 }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <button onClick={prevItem} className={styles.button_prev}>
        <FaChevronLeft className="w-5 h-5" />
      </button>
      <button onClick={nextItem} className={styles.button_next}>
        <FaChevronRight className="w-5 h-5" />
      </button>
    </div>
  )
}

export default SliderEquipo
