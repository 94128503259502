import type { Miembro } from '../../interfaces'

const SliderItem = ({ item }: { item: Miembro }) => {
  return (
    <div className="w-full rounded-tl-xl rounded-br-xl md:max-w-xs overflow-hidden">
      <div className="bg-secondary py-5 text-white px-4 h-[128px]">
        <h4 className="font-bold text-2xl">{item.name}</h4>
        <p className="text-xl">{item.desc}</p>
      </div>
      <div className="w-full md:w-[320px] h-[320px]">{item.image}</div>
    </div>
  )
}

export default SliderItem
