import { gql } from 'graphql-request'

export interface CreateContactanosVariables {
  email: string
  nombres: string
  mensaje: string
}

export interface CreateContactanosQuery {
  CreateContactanos: {
    nombres: string
    email: string
    mensaje: string
  }
}

export const CREATE_CONTACTANOS = gql`
  mutation CreateContactanos($input: ContactanosInput!) {
    CreateContactanos(input: $input) {
      nombres
      email
      mensaje
    }
  }
`
